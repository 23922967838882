import { Button, Form, Modal } from 'antd';
import styles from './index.module.less';

const CommonSimpleModal = ({
  title,
  desc,
  btnTitle,
  open,
  closeAction,
}: {
  title?: string | JSX.Element;
  desc?: string | JSX.Element;
  btnTitle?: string;
  open: boolean;
  closeAction?: (isOk: boolean, e?: any) => void;
}) => {
  return (
    <Modal
      // title=
      className={styles.modalContainer}
      open={open}
      onCancel={(e) => {
        if (closeAction) {
          closeAction(false, e);
        }
      }}
      footer={null}
      destroyOnClose={true}

    >
      <Form
        name="normal_edit_form"
        layout="vertical"
        className={styles.formContent}
        style={{ padding: '20px 24px' }}
        onClick={(e) => { e?.stopPropagation() }}
      >
        {title && (typeof title === 'string' ? <div className={styles.titleContainer}>{title}</div> : title)}

        {desc && (
          typeof desc === 'string' ? <Form.Item>
            <div className={styles.desc}>{desc}</div>
          </Form.Item> : desc
        )}

        {btnTitle && (
          <Form.Item>
            <Button
              className={styles.bottomButton}
              // style={{ color: `#FFF`, background: `var(--app-primary-color)` }}
              onClick={(e) => {
                if (closeAction) {
                  closeAction(true, e);
                }
              }}
            >
              {btnTitle}
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CommonSimpleModal;
